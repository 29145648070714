import { defineStore } from 'pinia';

import { fetchCourses, createCourse, updateCourse } from '../services/courses';
import type { ICourse } from '/@/types/interfaces';
import { computed, ref } from 'vue';
import { impersonate } from '/@/app/services/accounts';
import { useAccountStore } from '/@/app/store/account';
const accountStore = useAccountStore();
export const useEditorStore = defineStore('editor', () => {
  const courses = ref([] as ICourse[]);
  const publishedCourses = computed(
    () => courses.value.filter(c => c.published) as ICourse[],
  );
  const unPublishedCourses = computed(
    () => courses.value.filter(c => !c.published) as ICourse[],
  );
  const loading = ref<boolean>(true);

  const fetchEditorCourses = ({
    onlyPublished = false,
    onlyMyCourses = false,
  }) => {
    loading.value = true;
    const { onSuccess, onError } = fetchCourses({
      onlyPublished,
      onlyMyCourses,
    });
    onSuccess(({ data }) => {
      loading.value = false;
      courses.value = data;
    });
    onError(error => {
      loading.value = false;
      console.log(error);
    });
  };

  const createOrEditCourse = async (course: any) => {
    loading.value = true;
    return await new Promise((resolve, reject) => {
      const { onSuccess, onError } = course.id
        ? updateCourse(course.id, course)
        : createCourse(course);
      onSuccess(({ data }) => {
        console.log('here on success', data);
        loading.value = false;
        courses.value.push(data);
        resolve(data.id);
      });
      onError(error => {
        console.log('on error');
        loading.value = false;
        if (error.message.includes('refresh')) {
          alert(error.message);
        }
        reject(error);
      });
    });
  };

  const navigateToEditCourse = (courseId, target = '_self') => {
    const { onSuccess } = impersonate(accountStore.user.id);
    onSuccess(({ data }) => {
      const { token } = data;
      if (token) {
        window.open(
          `${import.meta.env.VITE_BUILDER_BASE}/c/${courseId}?token=${token}`,
          target,
        );
      }
    });
  };

  return {
    loading,
    courses,
    publishedCourses,
    unPublishedCourses,
    fetchEditorCourses,
    createOrEditCourse,
    navigateToEditCourse,
  };
});
